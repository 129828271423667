'use client';
import { useTheme } from 'next-themes';
import { ThemeLightIcon } from '@/app/components/icons/theme-light-icon';
import { ThemeDarkIcon } from '@/app/components/icons/theme-dark-icon';
import Button from '@/app/components/ui/button';
import { useIsMounted } from '@/lib/hooks/use-is-mounted';

export default function ThemeSwitcher() {
  const isMounted = useIsMounted();
  const { resolvedTheme, setTheme } = useTheme();
  const isDarkMode = resolvedTheme === 'dark';
  return (
    <Button
      variant="icon"
      aria-label="Theme Switcher"
      onClick={() => setTheme(isDarkMode ? 'light' : 'dark')}
      className="w-fit ml-3 lg:ml-0 mr-3"
    >
      <span className="absolute">
        {isMounted && isDarkMode && (
          <ThemeLightIcon className="h-6 w-6 text-brand" />
        )}
        {isMounted && !isDarkMode && (
          <ThemeDarkIcon className="h-[22px] w-[22px] text-brand-darkGray_3 hover:text-brand" />
        )}
      </span>
    </Button>
  );
}
