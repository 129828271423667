'use client';
import { USER_MENU } from '@/constants';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';
import Button from '../ui/button';
import { RxCross1 } from 'react-icons/rx';
import styles from '@/assets/css/styles';
import { useModalAction } from '../modal-views/context';

const MobileMenu = ({ mobileMenu, setMobileMenu, data }) => {
  const { openModal } = useModalAction();
  return (
    <div className="relative z-[50]">
      <div
        className={
          mobileMenu
            ? 'lg:hidden fixed left-0 top-0 w-full h-screen bg-black/70'
            : ''
        }
      >
        {/* Side Drawer Menu */}
        <div
          className={
            mobileMenu
              ? ' fixed left-0 top-0 w-full h-screen bg-brand-darkGray pt-3 ease-in duration-500'
              : 'fixed left-[-100%] top-0 p-10 ease-in duration-500 h-screen'
          }
        >
          <div>
            <div className="flex w-full items-center justify-between mb-5 px-5">
              <div onClick={() => setMobileMenu(!mobileMenu)}>
                <RxCross1 className="text-[26px] text-white cursor-pointer" />
              </div>
              <Link href="/">
                <Image
                  src="/icons/logo.svg"
                  alt=""
                  width="110"
                  height="35"
                  unoptimized={true}
                />
              </Link>
            </div>
          </div>

          <div className="w-full ps-14 m-auto text-white">
            {data?.user ? (
              <div className="flex items-center mb-8 gap-3">
                <div className="flex">
                  <Image
                    src="/images/winners.png"
                    alt=""
                    width={60}
                    height={60}
                    className="rounded-full w-[60px] h-[60px]"
                    unoptimized={true}
                  />
                </div>
                <h4
                  className={`font-semibold font-stratos text-[22px] uppercase px-1 py-1 text-center`}
                >
                  Hey {data?.user?.name}!
                </h4>
              </div>
            ) : (
              <div className="flex items-center pt-8 mb-5 cursor-pointer">
                <Button
                  onClick={() => openModal('LOGIN_VIEW')}
                  variant="btn_primary"
                  className="w-[180px] h-[40px]"
                >
                  Sign in or Join A4G
                </Button>
              </div>
            )}
            {/* Menu */}

            <div>
              {USER_MENU?.map((menu, index) => (
                <div key={index} className="py-1">
                  {menu?.heading && (
                    <h4 className={`${styles.menuHeading} py-2`}>
                      {menu?.heading}
                    </h4>
                  )}
                  {menu?.menu.map((item, index) => (
                    <Link
                      href={`${item?.link}`}
                      key={index}
                      className="text-white group flex gap-5 w-full items-center px-2 mb-3 text-sm lg:text-lg font-light  uppercase"
                      onClick={() => setMobileMenu(!mobileMenu)}
                    >
                      <div className="relative w-[22px] h-[22px]">
                        <Image
                          src={item?.icon}
                          // width={22}
                          // height={22}
                          fill
                          alt=""
                          unoptimized={true}
                          className="object-cover"
                        />
                      </div>
                      <div>{item?.menu}</div>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
