'use client';
import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { getProductSearchedData } from '@/app/product/[slug]/page-content/actions';
import { CloseIcon } from '@/app/components/icons/close-icon';
import { SpinnerIcon } from '@/app/components/icons/spinner-icon';
import SearchResult from './SearchResult';

const HeaderSearchInput = () => {
  const [txtSearch, setTxtSearch] = useState();
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searching, setSearching] = useState(false);

  const searchInputChange = (txt) => {
    setTxtSearch(txt);
  };

  useEffect(() => {
    const getSearchData = async (txt) => {
      const { data, loading } = await getProductSearchedData(txt);
      setSearchData(data);
      setSearching(false);
      return { data };
    };

    if (txtSearch?.trim()?.length > 0) {
      setSearching(true);
      setSearchData(null);
      setShowSearchDrop(true);
      getSearchData(txtSearch);
    } else {
      setShowSearchDrop(false);
      //   setSearchData(null);
    }
  }, [txtSearch]);

  return (
    <div className='hidden lg:block pt-6 relative w-[350px] xl:w-[450px] 2xl:w-[550px] mx-auto'>
      <input
        type="text"
        placeholder="Search giveaways, friends and community"
        className="font-light text-sm placeholder:text-gray-800 bg-light dark:bg-brand-darkGray rounded-xl [outline:none] px-6 py-3 w-full"
        autoComplete="off"
        value={txtSearch || ''}
        onChange={(e) => searchInputChange(e.target.value)}
      />

      <SearchResult showSearchDrop={showSearchDrop} searching={searching} searchData={searchData} setTxtSearch={setTxtSearch}/>
    </div>
  );
};

export default HeaderSearchInput;
