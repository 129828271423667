'use client';
import dynamic from 'next/dynamic';

import { ModalProvider } from '@/app/components/modal-views/context';
import ModalsContainer from '@/app/components/modal-views/container';
import DrawersContainer from '@/app/components/drawer-views/container';
import { Toaster } from 'react-hot-toast';
import Header from './header/Header';
import AssistanceRoute from './_assistanceRoute';
import Sidebar from './_layout-sidebar';

const BottomNavigation = dynamic(
  () => import('@/app/components/layouts/_bottom-navigation'),
);

const Footer = dynamic(() => import('@/app/components/layouts/_footer'));

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <ModalProvider>
      <Sidebar />
      <div className="lg:pl-[5%] bg-light-300 dark:bg-dark-100">
        <div className="px-5 md:px-10">
          <Header />
          {children}
        </div>
      </div>
      <AssistanceRoute />
      <Footer />
      <BottomNavigation />

      <ModalsContainer />
      <DrawersContainer />
      <Toaster containerClassName="!top-16 sm:!top-3.5 !bottom-16 sm:!bottom-3.5" />
    </ModalProvider>
  );
}
