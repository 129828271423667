'use client';
import Image from 'next/image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';
import { GoBellFill } from 'react-icons/go';
import HeaderMenu from './HeaderMenu';
import Button from '../../../components/ui/button';
import HeaderSearchInput from './HeaderSearch';
import { Menu } from '@/app/components/ui/dropdown';
import { UserIcon } from '@/app/components/icons/user-icon';
import { Transition } from '@/app/components/ui/transition';
import SearchButton from '@/app/components/layouts/search-button';
import CartButton from '@/app/components/cart/cart-button';
import { useScrollDirection } from '@/lib/hooks/use-scroll-direction';
import { useModalAction } from '@/app/components/modal-views/context';
import { NotificationIcon } from '../../icons/notification-icon';
import ActiveLink from '@/app/components/ui/links/active-link';
import Script from 'next/script';
import ThemeSwitcher from '../../ui/theme-switcher';
import { signIn, useSession, signOut } from 'next-auth/react';
import Logo from '../../ui/logo';
import { getProductSearchedData } from '@/app/product/[slug]/page-content/actions';
import SearchResult from './SearchResult';
import { RxCross2 } from 'react-icons/rx';
import { IoMdMenu } from 'react-icons/io';
import MobileMenu from '../MobileMenu';

const AuthorizedMenuItems = [
  {
    label: 'My Account',
    path: '/mya4g/myaccount',
  },
  {
    label: 'My Orders',
    path: '/mya4g/myorders',
  },
  {
    label: 'My Tickets',
    path: '/mya4g/mytickets',
  },
];

function AuthorizedMenu({ user }) {
  return (
    <Menu>
      <Menu.Button>
        <Image
          src="/images/winners.png"
          alt=""
          width={33}
          height={33}
          className="rounded-full cursor-pointer"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-[55px] right-0 z-30 mt-4 w-56 rounded-md bg-light py-1.5 text-dark shadow-dropdown font-interstate font-medium  text-[13px] uppercase  whitespace-nowrap dark:bg-dark-250 dark:text-light">
          <Menu.Item>
            <div className="p-3 text-sm text-brand dark:text-white capitalize font-bold">{`Hi, ${user?.name} !`}</div>
          </Menu.Item>
          {AuthorizedMenuItems.map((item) => (
            <Menu.Item key={item.label}>
              <ActiveLink
                href={item.path}
                className="transition-fill-colors flex w-full items-center px-5 py-2.5 hover:bg-light-400 dark:hover:bg-dark-600"
              >
                {item.label}
              </ActiveLink>
            </Menu.Item>
          ))}
          <Menu.Item>
            <div
              // type="button"
              className="transition-fill-colors w-full px-5 py-2.5 hover:bg-light-400  dark:hover:bg-dark-600 cursor-pointer"
              onClick={() =>
                signOut({
                  callbackUrl: '/',
                  redirect: true,
                })
              }
            >
              Logout
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function SigninButton() {
  const { openModal } = useModalAction();
  return (
    // <Link href="/login">
    <Button
      variant="btn_primary"
      size="size_normal"
      radius="rounded-[15px]"
      className="hidden lg:flex"
      onClick={() => openModal('LOGIN_VIEW')}
    >
      Sign in or Join A4G
    </Button>
    // </Link>
  );
}

function Notification() {
  // const { openModal } = useModalAction();

  return (
    <Button
      variant="icon"
      aria-label="notification"
      className="flex "
      // onClick={() => openModal('LOGIN_VIEW')}
    >
      <GoBellFill className="h-6 w-6 cursor-pointer text-brand-darkGray_3 dark:text-white hover:text-brand dark:hover:text-brand " />
    </Button>
  );
}

const HeaderContainer = () => {
  const { openModal } = useModalAction();

  const scrollDirection = useScrollDirection();
  const { status, data } = useSession();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const [txtSearch, setTxtSearch] = useState();
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searching, setSearching] = useState(false);
  const inputRef = useRef(null);

  const searchInputChange = (txt) => {
    setTxtSearch(txt);
  };

  useEffect(() => {
    const getSearchData = async (txt) => {
      const { data, loading } = await getProductSearchedData(txt);
      setSearchData(data);
      setSearching(false);
      return { data };
    };

    if (txtSearch?.trim()?.length > 0) {
      setSearching(true);
      setSearchData(null);
      setShowSearchDrop(true);
      getSearchData(txtSearch);
    } else {
      setShowSearchDrop(false);
      //   setSearchData(null);
    }

    if (showSearchBar) {
      inputRef.current.focus();
    }
  }, [txtSearch, showSearchBar, inputRef]);

  return (
    <div className="">
      <header className="flex justify-between py-2 lg:py-0 mb-6">
        <div className="flex items-center">
          <IoMdMenu
            className="text-dark-600 dark:text-white block lg:hidden text-3xl cursor-pointer"
            onClick={() => setMobileMenu(!mobileMenu)}
          />
          <Logo className="lg:ml-0" />
        </div>
        <div className="flex flex-col gap-y-8 justify-center lg:w-[550px] 2xl:w-[620px]">
          <HeaderSearchInput />
          <HeaderMenu />
        </div>

        <div className="mt-4 lg:mt-6">
          <div className="flex items-center gap-3 lg:gap-5 text-brand-dark dark:text-white text-[22px] lg:text-xl">
            <IoSearch
              className="w-6 h-6 block lg:hidden cursor-pointer text-2xl text-brand-darkGray_3 dark:text-white hover:text-brand dark:hover:text-brand"
              onClick={() => setShowSearchBar(!showSearchBar)}
            />
            {showSearchBar && (
              <div className="absolute top-[68px] left-0 block lg:hidden w-full">
                <div className={`${showSearchBar && 'block h-[40px] mb-2'}`}>
                  <input
                    type="text"
                    placeholder="Search Here.."
                    className="w-full h-[32px] pl-4 pr-8 rounded-md font-interstate font-normal text-sm"
                    autoComplete="off"
                    value={txtSearch || ''}
                    onChange={(e) => searchInputChange(e.target.value)}
                    ref={inputRef}
                  />
                  <RxCross2
                    className="absolute top-[13px] right-[14px] text-sm cursor-pointer"
                    onClick={() => setShowSearchBar(!showSearchBar)}
                  />

                  <SearchResult
                    showSearchDrop={showSearchDrop}
                    searching={searching}
                    searchData={searchData}
                    setTxtSearch={setTxtSearch}
                    top="top-[2.6rem]"
                  />
                </div>
              </div>
            )}
            <ThemeSwitcher />
            {/* <VscBellDot className="text-brand"/> */}
            {data?.user && <Notification />}
            {!data?.user && (
              <FaUserCircle
                onClick={() => openModal('LOGIN_VIEW')}
                className="w-6 h-6 block lg:hidden cursor-pointer text-2xl text-brand-darkGray_3 dark:text-[#b8b8b8] hover:text-brand dark:hover:text-brand"
              />
            )}

            {data?.user ? (
              // <Button variant="icon" aria-label="User" className="flex ">
              // </Button>
              <AuthorizedMenu user={data.user} />
            ) : (
              <SigninButton />
            )}
            {/* <UserImage user={data?.user} /> */}
          </div>
        </div>
      </header>

      {/* <HeaderMenu /> */}

      {/* <Script id="tawk" strategy="lazyOnload">
        {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/65c09b8d0ff6374032c9674c/1hls5v8qo';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();  
        `}
      </Script> */}

      {/* Mobile menu */}
      <MobileMenu
        setMobileMenu={setMobileMenu}
        mobileMenu={mobileMenu}
        data={data}
      />
    </div>
  );
};

export default HeaderContainer;
