import { MENU } from '@/constants';
import Image from 'next/image';
import CartButton from '@/app/components/cart/cart-button';
import Link from 'next/link';

const HeaderMenu = () => {
  return (
    <div className="hidden md:block mt-6 lg:mt-0 w-[340px] lg:w-auto">
      <nav className="hidden md:flex justify-center bg-white dark:bg-brand-darkGray rounded-2xl py-2.5 lg:py-4 px-10 lg:px-16 w-full lg:mt-[-15px] mb-3 ">
        <ul className="flexBetween w-full home_menu">
          {MENU?.map((menu, index) => (
            <Link
              key={index}
              href={`${menu?.link}`}
              className={`cursor-pointer relative ${menu?.class}`}
            >
              <span className='w-[26] h-[25] text-brand-darkGray_3 dark:text-white hover:text-brand dark:hover:text-brand'>{menu?.icon}</span>
            </Link>
          ))}
          <CartButton className="hover:text-brand" />
        </ul>
      </nav>
    </div>
  );
};

export default HeaderMenu;
