// icon:trophy-award | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from 'react';

export function StarPointsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.6633 8.96782L16.0588 13.0195C15.9705 13.0831 15.9339 13.1956 15.9676 13.2981L17.016 16.4768C17.0586 16.6064 17.0677 16.7456 17.0422 16.8789C17.025 16.9702 17.0601 17.0632 17.1338 17.1208C17.2688 17.2264 17.3685 17.3744 17.4142 17.5375L18.0993 19.9746C18.1931 20.3075 18.0623 20.6633 17.7739 20.8599C17.4895 21.0519 17.0954 21.0458 16.819 20.8464L11.1906 16.6331C11.1017 16.5705 10.982 16.5712 10.8937 16.635L5.18089 20.7637C4.90446 20.9631 4.51038 20.9693 4.22592 20.7773C3.93759 20.5806 3.80672 20.225 3.90056 19.8919L4.58564 17.4548C4.63136 17.2916 4.73108 17.1437 4.86609 17.0382C4.93961 16.9806 4.9749 16.8875 4.95766 16.7963C4.93213 16.6629 4.94122 16.5237 4.98386 16.3942L6.03226 13.2155C6.06608 13.1129 6.02932 13.0006 5.9411 12.9368L0.33654 8.88518C0.0483386 8.67745 -0.0702304 8.31327 0.0416549 7.97808C0.152203 7.64355 0.466605 7.41868 0.82405 7.41868H3.59084C3.71836 7.41868 3.84535 7.44834 3.95791 7.50423C4.03624 7.54324 4.12928 7.53942 4.20387 7.49447C4.33647 7.41473 4.48432 7.37255 4.63163 7.37255H7.83513C7.94594 7.37255 8.04379 7.30151 8.07681 7.19712L10.1723 0.571256C10.2791 0.233432 10.5918 0.00382243 10.9587 0C11.3139 0 11.6279 0.223415 11.7403 0.555835L12.61 3.12952C12.6438 3.23101 12.6565 3.33962 12.6473 3.45232C12.6411 3.5289 12.6711 3.60403 12.7285 3.65596C12.8386 3.75574 12.9193 3.88399 12.9619 4.02634L13.9232 7.27976C13.9562 7.38415 14.0542 7.4552 14.1649 7.4552H17.3684C17.5157 7.4552 17.6635 7.49737 17.7961 7.57712C17.8707 7.62206 17.9636 7.62602 18.0421 7.587C18.1548 7.53099 18.2818 7.50146 18.4092 7.50146H21.1759C21.5333 7.50146 21.8478 7.72619 21.9583 8.06085C22.0702 8.39604 21.9517 8.76023 21.6635 8.96796L21.6633 8.96782Z"
        fill="currentColor"
      />
    </svg>
  );
}
