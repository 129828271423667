'use client'
import { usePathname } from 'next/navigation';
import Assistance from './_assistance';

const AssistanceRoute = () => {
    const pathname = usePathname()
    return (
        <>
           {
            pathname !== '/' & pathname !== '/email-verification/' & (pathname !== "/get-started/")  ? <Assistance/> : ''
           } 
        </>
    );
};

export default AssistanceRoute;