import Link from 'next/link';
import Heading from '../ui/Heading';
import Button from '../ui/button';
const Assistance = () => {
  return (
    <div className="pt-14 lg:pt-20 md:pt-0 bg-light-300 dark:bg-brand-dark">
      <div className="w-screen bg-brand py-12 text-center">
        <h6 className="neu-medium-base text-brand-gray_1">
          24/7 ASSISTANCE FOR WHEN YOU NEED US
        </h6>
        <Heading
          title="Can we help sausage?"
          className="mt-2 mb-9 "
          mb="lg:mb-6"
        />
        <div className="flex items-center gap-5 justify-center md:w-[350px] md:m-auto">
          <Link href="/chat">
            <Button
              variant="btn_white"
              size="size_medium"
              radius="rounded-[15px]"
            >
              START LIVE CHAT
            </Button>
          </Link>
          <Link href="/help">
            <Button
              variant="btn_dark"
              size="size_medium"
              radius="rounded-[15px]"
            >
              EMAIL US
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Assistance;
