import Image from '@/app/components/ui/image';
import AnchorLink from '@/app/components/ui/links/anchor-link';
import GambleawareImg from '@/assets/images/begambleaware-1024x133.png';
import payviaImg from '@/assets/images/payvia.png';
import ActiveLink from '@/app/components/ui/links/active-link';
import Copyright from '@/app/components/layouts/_copyright';
import MenuItems from '@/app/components/layouts/_menu-items';
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import Link from 'next/link';

const PayviaImg = () => {
  return (
    <div
      className={
        'relative flex items-center justify-center text-dark focus:outline-none dark:text-light'
      }
    >
      <span
        className="relative overflow-hidden"
        style={{
          width: '85%',
          height: 35,
        }}
      >
        <Image
          src={payviaImg}
          fill
          loading="eager"
          alt=""
          className="object-contain"
        />
      </span>
    </div>
  );
};

const Begambleaware = ({ ...props }: React.AnchorHTMLAttributes<{}>) => {
  return (
    <AnchorLink
      href={'https://www.begambleaware.org/'}
      target=""
      className={
        'relative flex items-center justify-center text-dark focus:outline-none dark:text-light mb-2'
      }
      {...props}
    >
      <span
        className="relative overflow-hidden"
        style={{
          width: '85%',
          height: 35,
        }}
      >
        <Image
          src={GambleawareImg}
          fill
          loading="eager"
          alt=""
          className="object-contain"
        />
      </span>
    </AnchorLink>
  );
};

export default function Sidebar() {
  return (
    <aside
      className={
        'group hidden lg:flex h-full fixed left-0 z-[150] pt-8 top-0  flex-col justify-between overflow-y-auto border-r border-light-400 bg-light-100 text-dark-900 dark:border-0 dark:bg-dark-100 w-20  transition-all ease-in-out duration-300 hover:w-64 lg:pb-2'
      }
    >
      <MenuItems />
      {/* <nav className={'bottom-0 flex-col pb-4 lg:hidden group-hover:lg:flex'}>
        <Begambleaware />
        <PayviaImg />
      </nav> */}

      {/* Social icons */}
      <div className='bottom-0 pb-4 lg:hidden group-hover:lg:flex gap-5 items-center justify-center text-brand-darkGray_3 dark:text-white mt-10'>
      <Link href="https://www.facebook.com/all4giveaways" target='_blank' className='hover:text-brand dark:hover:text-brand text-xl'>      <FaFacebook /></Link>
      <Link href="https://www.youtube.com/channel/UCzmiD2WAqmXuovTuG0dr_OA" target='_blank' className='hover:text-brand dark:hover:text-brand text-xl'> <FaYoutube /></Link>
      <Link href="https://www.tiktok.com/@allforgiveaways?lang=en" target='_blank' className='hover:text-brand dark:hover:text-brand text-xl'>    <FaTiktok /> </Link>

      
      </div>
      <footer className="flex-col border-t m-3  border-light-400 pt-3 pb-4 text-center dark:border-dark-400 lg:hidden group-hover:lg:flex">
        <nav className="flex items-center justify-center gap-5 pb-1.5 text-13px  font-normal capitalize tracking-[0.2px]">
          <ActiveLink
            href={'/terms'}
            className="block py-1 text-orange-400 hover:text-dark-100 dark:hover:text-brand"
          >
            {'Terms'}
          </ActiveLink>
          <ActiveLink
            href={'/privacy'}
            className="block py-1 text-orange-400 hover:text-dark-100 dark:hover:text-brand"
          >
            {'Privacy'}
          </ActiveLink>
          <ActiveLink
            href={'/help'}
            className="block py-1 text-orange-400 hover:text-dark-100 dark:hover:text-brand"
          >
            {'Help'}
          </ActiveLink>
        </nav>
        <Copyright className="text-xs font-medium text-dark-800/80 dark:text-dark-700" />
      </footer>
    </aside>
  );
}
