// icon:newspaper-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from 'react';

export function NewspaperOutlineIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6825 2.96795C22.1159 3.08409 22.3732 3.52961 22.257 3.96306L21.8605 5.44292C21.7444 5.87636 21.2988 6.13358 20.8654 6.01744C20.432 5.9013 20.1747 5.45578 20.2909 5.02234L20.6874 3.54247C20.8035 3.10903 21.2491 2.85181 21.6825 2.96795ZM17.1909 4.80548C16.4621 3.54303 14.7196 3.34608 13.7273 4.41399L6.74141 11.9324C6.98512 12.1535 7.19915 12.4156 7.37249 12.7159L10.0808 17.4068C10.2542 17.7071 10.3742 18.0236 10.4438 18.3452L20.4479 16.0544C21.8689 15.7291 22.5696 14.1215 21.8407 12.8591L17.1909 4.80548ZM8.50219 18.1211L5.79385 13.4301C5.34512 12.6529 4.35129 12.3866 3.57406 12.8354C2.79684 13.2841 2.53054 14.2779 2.97927 15.0551L5.6876 19.7461C6.13634 20.5233 7.13017 20.7896 7.9074 20.3409C8.68462 19.8922 8.95092 18.8983 8.50219 18.1211ZM14.8518 22.909L12.7898 19.3375L16.1044 18.5785L17.6664 21.284C18.1151 22.0613 17.8488 23.0551 17.0716 23.5038C16.2943 23.9525 15.3005 23.6862 14.8518 22.909ZM23.4526 8.20067C23.0192 8.08453 22.5737 8.34175 22.4575 8.77519C22.3414 9.20863 22.5986 9.65416 23.0321 9.7703L24.5119 10.1668C24.9454 10.283 25.3909 10.0257 25.507 9.5923C25.6232 9.15886 25.3659 8.71334 24.9325 8.59719L23.4526 8.20067Z"
        fill="currentColor"
      />
    </svg>
  );
}
