export const HelpIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3767 11.1883C22.3767 17.3675 17.3675 22.3767 11.1883 22.3767C5.00919 22.3767 0 17.3675 0 11.1883C0 5.00919 5.00919 0 11.1883 0C17.3675 0 22.3767 5.00919 22.3767 11.1883ZM11.1884 5.22532C9.74883 5.22532 8.58186 6.39229 8.58186 7.83182C8.58186 8.24603 8.24608 8.58182 7.83186 8.58182C7.41765 8.58182 7.08186 8.24603 7.08186 7.83182C7.08186 5.56386 8.92041 3.72532 11.1884 3.72532C13.4563 3.72532 15.2949 5.56386 15.2949 7.83182C15.2949 9.68131 14.0728 11.2434 12.3938 11.7583C12.2446 11.8041 12.1179 11.8856 12.0369 11.9743C11.9598 12.0586 11.9384 12.1308 11.9384 12.1883V13.426C11.9384 13.8402 11.6026 14.176 11.1884 14.176C10.7742 14.176 10.4384 13.8402 10.4384 13.426V12.1883C10.4384 11.1869 11.2425 10.5425 11.954 10.3243C13.0207 9.99712 13.7949 9.0039 13.7949 7.83182C13.7949 6.39229 12.6279 5.22532 11.1884 5.22532ZM11.1884 17.9013C11.8063 17.9013 12.3072 17.4004 12.3072 16.7825C12.3072 16.1646 11.8063 15.6637 11.1884 15.6637C10.5704 15.6637 10.0695 16.1646 10.0695 16.7825C10.0695 17.4004 10.5704 17.9013 11.1884 17.9013Z"
        fill="currentColor"
      />
    </svg>
  );
};
