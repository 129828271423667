import React from 'react';
import { SpinnerIcon } from '../../icons/spinner-icon';
import { CloseIcon } from '../../icons/close-icon';
import Link from 'next/link';

const SearchResult = ({
  showSearchDrop,
  searching,
  searchData,
  setTxtSearch,
  top
}) => {
  return (
    <div className={`absolute ${top? top : 'top-[4.5rem]'} z-50 w-full`}>
      <div className="container">
        {showSearchDrop && (
          <div className="d-block bg-white h-[500px] max-h-[500px] overflow-scroll">
            <ul>
              <li className="flex justify-between  p-1 text-dark bg-gray-100 ">
                {searching && (
                  <span className="flex  w-full p-2 px-4   ">
                    <SpinnerIcon className=" h-auto w-6 animate-spin mr-4" />
                    <span className='text-sm lg:text-base'>Searching...</span>
                  </span>
                )}

                {!searching && searchData?.products?.nodes?.length == 0 && (
                  <span className=" p-2 px-5 text-dark text-sm">
                    No Matched Product Found
                  </span>
                )}

                {!searching && searchData?.products?.nodes?.length > 0 && (
                  <span className=" p-2 px-5 text-sm">
                    {`${searchData?.products?.nodes?.length}`} Matched Product
                    Found
                  </span>
                )}

                <span
                  className="cursor-pointer flex items-center px-2 hover:text-red-500 text-dark font-bold"
                  onClick={() => setTxtSearch('')}
                >
                  <CloseIcon width={20} height={20} />
                </span>
              </li>

              {!searching &&
                searchData?.products?.nodes?.map((p, i) => (
                  <li
                    key={i}
                    className="p-1 px-4 hover:bg-brand hover:text-dark"
                    onClick={() => setTxtSearch('')}
                  >
                    <Link
                      className="text-dark text-sm"
                      href={`/product/${p?.slug}`}
                    >
                      {p?.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
