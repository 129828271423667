// icon:broadcast-line | Remix Icon https://remixicon.com/ | Remix Design
import * as React from 'react';

export function BroadcastIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50002 2.25C4.10679 2.25 2.16669 4.26472 2.16669 6.75V20.25C2.16669 22.7353 4.10679 24.75 6.50002 24.75H19.5C21.8933 24.75 23.8334 22.7353 23.8334 20.25V6.75C23.8334 4.26472 21.8933 2.25 19.5 2.25H6.50002ZM7.58335 20.8125C8.48082 20.8125 9.20835 20.057 9.20835 19.125C9.20835 18.193 8.48082 17.4375 7.58335 17.4375C6.68589 17.4375 5.95835 18.193 5.95835 19.125C5.95835 20.057 6.68589 20.8125 7.58335 20.8125ZM5.69378 12.2707C5.74931 11.8083 6.15529 11.4802 6.60058 11.5378C10.9248 12.0978 14.3504 15.6551 14.8896 20.1457C14.9452 20.6081 14.6292 21.0297 14.1839 21.0874C13.7386 21.145 13.3326 20.8169 13.2771 20.3545C12.8299 16.6303 9.98573 13.6768 6.3995 13.2124C5.95422 13.1547 5.63826 12.7331 5.69378 12.2707ZM6.50002 5.90625C6.05129 5.90625 5.68752 6.28401 5.68752 6.75C5.68752 7.21599 6.05129 7.59375 6.50002 7.59375C13.231 7.59375 18.6875 13.2601 18.6875 20.25C18.6875 20.716 19.0513 21.0938 19.5 21.0938C19.9488 21.0938 20.3125 20.716 20.3125 20.25C20.3125 12.3282 14.1285 5.90625 6.50002 5.90625Z"
        fill="currentColor"
      />
    </svg>
  );
}
