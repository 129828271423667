'use client';
import { usePathname } from 'next/navigation';
import HeaderContainer from './HeaderContainer';

const Header = () => {
  const pathname = usePathname();
  return (
    <div className='sticky top-0 bg-light-300 dark:bg-brand-dark z-[100]'>
      {(pathname !== '/email-verification/') &
      (pathname !== '/get-started/') ? (
        <HeaderContainer />
      ) : (
        ''
      )}
    </div>
  );
};

export default Header;
