export const DownloadIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.41667 16.4167C9.58333 16.5833 9.83333 16.6667 10 16.6667C10.25 16.6667 10.4167 16.5833 10.5833 16.4167L16.4167 10.5833C16.6667 10.3333 16.75 10 16.5833 9.66667C16.5 9.33333 16.1667 9.16667 15.8333 9.16667H13.3333V0.833333C13.3333 0.333333 13 0 12.5 0H7.5C7 0 6.66667 0.416667 6.66667 0.833333V9.16667H4.16667C3.83333 9.16667 3.5 9.33333 3.41667 9.66667C3.25 10 3.33333 10.3333 3.58333 10.5833L9.41667 16.4167Z"
        fill="currentColor"
      />
      <path
        d="M19.1667 15C18.6667 15 18.3333 15.3333 18.3333 15.8333V18.3333H1.66667V15.8333C1.66667 15.3333 1.33333 15 0.833333 15C0.333333 15 0 15.3333 0 15.8333V19.1667C0 19.6667 0.333333 20 0.833333 20H19.1667C19.6667 20 20 19.6667 20 19.1667V15.8333C20 15.3333 19.5833 15 19.1667 15Z"
        fill="currentColor"
      />
    </svg>
  );
};
